//   ____                       _ _
//  / ___|  ___  ___ _   _ _ __(_) |_ _   _
//  \___ \ / _ \/ __| | | | '__| | __| | | |
//   ___) |  __/ (__| |_| | |  | | |_| |_| |
//  |____/ \___|\___|\__,_|_|  |_|\__|\__, |
//                                    |___/
import axios from 'axios'
import _forEach from 'lodash/forEach'
import _has from 'lodash/has'

export const security = {
  namespaced: true,
  state: () => ({
    error: null,
    isAuthenticated: false,
    token: localStorage.getItem('token') || '',
    user: null
  }),
  getters: {
    hasError (state) {
      return state.error !== null
    },
    error (state) {
      return state.error
    },
    user (state) {
      if (!state.user) {
        if (state.token !== '') {
          return JSON.parse(atob(state.token.split('.')[1]))
        } else {
          return null
        }
      } else {
        return state.user
      }
    },
    isAuthenticated (state) {
      return state.token !== ''
    },
    hasRole: (state) => (role) => {
      let user = state.user
      if (!user) {
        user = JSON.parse(atob(state.token.split('.')[1]))
      }
      if (user) {
        return user.roles.indexOf(role) !== -1
      } else {
        return false
      }
    },
    userHasRole: (state, getters) => (roles) => {
      if (getters.hasRole('ROLE_ADMIN')) {
        return true
      } else {
        let hasRole = false
        _forEach(roles, function (role) {
          if (getters.hasRole(role)) {
            hasRole = true
          }
        })
        return hasRole
      }
    },
    userHasNotRole: (state, getters) => (roles) => {
      let hasnotRole = true
      _forEach(roles, function (role) {
        if (getters.hasRole(role)) {
          hasnotRole = false
        }
      })
      return hasnotRole
    }
  },
  mutations: {
    authenticatingSuccess: function (state, token) {
      state.error = null
      state.isAuthenticated = true
      state.token = token
      // Token deserialisieren und als User Objekt speichern
      state.user = JSON.parse(atob(token.split('.')[1]))
      // Localstorage und Axios
      localStorage.setItem('token', token)
    },
    authenticatingError: function (state, error) {
      state.error = error
      state.isAuthenticated = false
      localStorage.removeItem('token')
      state.token = ''
      state.user = null
    },
    authenticationLogout: function (state) {
      state.error = null
      state.isAuthenticated = false
      state.token = ''
      state.user = null
      localStorage.removeItem('token')
      delete axios.defaults.headers.common.authorization
    }
  },
  actions: {
    login: async function ({ commit, dispatch }, user) {
      console.log('test')
      try {
        return await this.$app.axios.post('/auth/login', {
          username: user.username,
          password: user.password
        }).then(function (response) {
          // Erfolgreiche Authentifizierung
          console.log('test2')
          console.log(response)
          console.log(response.status)
          commit('authenticatingSuccess', response.data.token)
        })
          .catch(function (error) {
            // Es soll trotz eines 401 den Error aus dem Response Body angezeigt werden
            if (error.response.status === 401) {
              const data = error.response.data
              if (_has(data, 'message')) {
                commit('authenticatingError', data.message)
              } else {
                // Sollte nicht eintreten, malformed json response
                commit('authenticatingError', error)
              }
            } else {
              // Anderer HTTP-Status-Code (zum Beispiel 500)
              commit('authenticatingError', error)
            }
          })
      } catch (e) {
        console.log('test')
        console.log(e)
      }
    },
    logout: function ({ commit, dispatch }) {
      return commit('authenticationLogout')
    }
  }
}
