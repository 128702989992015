// import axios from 'axios'
// import _has from 'lodash/has'
// import _forEach from 'lodash/forEach'

export const users = {
  namespaced: true,
  state: () => ({
    error: null
  }),
  getters: {},
  setters: {}
}
