//    ____ ___  ____  _____ _   _ ___
//   / ___/ _ \|  _ \| ____| | | |_ _|
//  | |  | | | | |_) |  _| | | | || |
//  | |___ |_| |  _ <| |___| |_| || |
//   \____\___/|_| \_\_____|\___/|___|
//
export const coreui = {
  namespaced: true,
  state: {
    darkMode: true,
    smallSideBar: localStorage.getItem('smallSideBar') || true,
    showSideBar: localStorage.getItem('showSideBar') || true,
    profiModus: localStorage.getItem('profiModus') || false
  },
  getters: {
    darkMode (state) {
      let darkMode = state.darkMode
      if (typeof darkMode === 'string') {
        darkMode = JSON.parse(darkMode)
      }
      return darkMode
    },
    showSideBar (state) {
      let showSideBar = state.showSideBar
      if (typeof showSideBar === 'string') {
        showSideBar = JSON.parse(showSideBar)
      }
      return showSideBar
    },
    smallSideBar (state) {
      let smallSideBar = state.smallSideBar
      if (typeof smallSideBar === 'string') {
        smallSideBar = JSON.parse(smallSideBar)
      }
      return smallSideBar
    },
    profiModus (state) {
      let profiModus = state.profiModus
      if (typeof profiModus === 'string') {
        profiModus = JSON.parse(profiModus)
      }
      return profiModus
    }
  },
  mutations: {
    TOGGLE_DARKMODE (state, mode) {
      state.darkMode = !state.darkMode
      localStorage.setItem('darkMode', state.darkMode)
    },
    TOGGLE_SMALL_SIDEBAR (state) {
      state.smallSideBar = !state.smallSideBar
      localStorage.setItem('smallSideBar', state.smallSideBar)
    },
    TOGGLE_SHOW_SIDEBAR (state) {
      state.showSideBar = !state.showSideBar
      localStorage.setItem('showSideBar', state.showSideBar)
    },
    TOGGLE_PROFIMODUS (state) {
      state.profiModus = !state.profiModus
      localStorage.setItem('profiModus', state.profiModus)
    }
  }
}
