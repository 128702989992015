export const adressSelection = {
  namespaced: true,
  state: () => ({
    error: null,
    selected: []
  }),
  getters: {
    selected (state) {
      return state.selected
    }
  },
  mutations: {
    selectedChange: function (state, selected) {
      state.error = null
      state.selected = selected
    }
  },
  actions: {
    setSelected ({ commit, dispatch }, selected) {
      commit('selectedChange', selected)
    }
  }
}
