<template>
  <AppSidebar />
  <div
    class="wrapper d-flex flex-column min-vh-100 bg-light dark:bg-transparent"
  >
    <AppHeader />
    <div class="body flex-grow-1 px-3">
      <CContainer lg>
        <router-view />

        <div class="row center text-center mt-5">
          <div class="col">
            <img :src="logo" alt="tkrz" class="tarife-img" style="width: 100px;"/>
            <img :src="ems300" alt="ems300" class="tarife-img" style="width: 100px;"/>
            <img :src="ems500" alt="ems500" class="tarife-img" style="width: 100px;"/>
            <img :src="ems1000" alt="ems1000" class="tarife-img" style="width: 100px;"/>
          </div>
        </div>
      </CContainer>
    </div>
  </div>
</template>
<script>
import { CContainer } from '@coreui/vue-pro'
import AppHeader from '@/coreui//components/default/AppHeader.vue'
import AppSidebar from '@/coreui//components/default/AppSidebar.vue'
import { useStore } from 'vuex'

import logo from '@/assets/brand/logo_tkrz.svg'
import ems500 from '@/assets/brand/ems500.png'
import ems300 from '@/assets/brand/ems300.png'
import ems1000 from '@/assets/brand/ems1000.png'

export default {
  name: 'DefaultLayout',
  components: {
    AppHeader,
    AppSidebar,
    CContainer
  },
  setup () {
    const store = useStore()
    store.dispatch('config/load')

    return {
      ems300,
      ems500,
      ems1000,
      logo
    }
  },
  async created () {

  }
}
</script>

<style scoped lang="scss">
 .tarife-img {
   justify-content: center;
   margin: 20px;
 }
</style>
