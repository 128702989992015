import { createStore } from 'vuex'

import { coreui } from './coreui'
import { security } from './security'
import { users } from './users'
import { products } from './products'
import { tags } from './tags'
import { options } from './options'
import { tarifes } from './tarifes'
import { checkoutLinks } from './checkoutLinks'
import { adressSelection } from './adressSelection'
import { config } from './config'

export default createStore({
  modules: {
    config,
    coreui,
    security,
    users,
    options,
    tags,
    tarifes,
    products,
    adressSelection,
    checkoutLinks
  }
})
