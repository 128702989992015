<template>
  <CSidebar
    position="fixed"
    :unfoldable="smallSidebar"
    :visible="showSidebar"
  >
    <CSidebarBrand>
      <img :src="logo" alt="Cows Online Full" class="sidebar-brand-full" />
      <img :src="logo" alt="Cows Online" class="sidebar-brand-narrow" />
    </CSidebarBrand>
    <AppSidebarNav />
    <hr/>
    <div class="">
      <AppSidebarNavFooter />
    </div>

    <CSidebarToggler
      class="d-none d-lg-flex"
      @click="toggleSmallSidebar"
    />
  </CSidebar>
</template>

<script>
import { AppSidebarNav } from './AppSidebarNav'
import { AppSidebarNavFooter } from './AppSidebarNavFooter'
import logo from '@/assets/brand/logo_tkrz.svg'

export default {
  name: 'AppSidebar',
  components: {
    AppSidebarNav,
    AppSidebarNavFooter
  },
  setup () {
    return {
      logo
    }
  },
  computed: {
    smallSidebar () {
      return this.$store.getters['coreui/smallSideBar']
    },
    showSidebar () {
      return this.$store.getters['coreui/showSideBar']
    }
  },
  methods: {
    toggleSmallSidebar () {
      this.$store.commit('coreui/TOGGLE_SMALL_SIDEBAR')
    }
  }
}
</script>
