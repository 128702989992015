<template>
  <router-view />
</template>

<script>
export default {
  mounted () {
    this.setTheme()
  },
  computed: {
    darkMode () {
      return this.$store.getters['coreui/darkMode']
    }
  },
  watch: {
    darkMode: function () {
      this.setTheme()
    }
  },
  methods: {
    setTheme () {
      const darkMode = this.$store.getters['coreui/darkMode']
      darkMode
        ? document.body.classList.remove('dark-theme')
        : document.body.classList.add('dark-theme')
    }
  }
}
</script>

<style lang="scss">
@import 'assets/scss/style';
</style>
