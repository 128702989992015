import {
  cilBan,
  cilBoltCircle,
  cilCasino,
  cilEnvelopeClosed,
  cilGroup,
  cilHighlighter,
  cilHttps,
  cilLibraryAdd,
  cilLockLocked,
  cilLockUnlocked,
  cilLowVision,
  cilMagnifyingGlass,
  cilMenu,
  cilMediaSkipBackward,
  cilMediaSkipForward,
  cilMoon,
  cilPlus,
  cilSpeedometer,
  cilStorage,
  cilSun,
  cilThumbDown,
  cilThumbUp,
  cilTrash,
  cilUser,
  cilAddressBook,
  cilListRich,
  cilCommentBubble,
  cilHospital,
  cilHome,
  cilHamburgerMenu,
  cilStar,
  cilStarHalf,
  cilSearch,
  cilCart,
  cilCloudUpload,
  cilExpandDown,
  cilArrowLeft,
  cilArrowRight,
  cilArrowUp,
  cilListLowPriority,
  cilTags,
  cilOptions,
  cilArrowDown,
  cilCreditCard,
  cilCloudDownload,
  cilInfo,
  cilX,
  cilMonitor,
  cilSync,
  cilWarning
} from '@coreui/icons'

export const iconsSet = Object.assign(
  {},
  {
    cilBan,
    cilBoltCircle,
    cilCasino,
    cilEnvelopeClosed,
    cilGroup,
    cilHighlighter,
    cilHttps,
    cilLibraryAdd,
    cilLockLocked,
    cilLockUnlocked,
    cilLowVision,
    cilListLowPriority,
    cilMagnifyingGlass,
    cilMediaSkipBackward,
    cilMediaSkipForward,
    cilMoon,
    cilMenu,
    cilPlus,
    cilX,
    cilSpeedometer,
    cilStorage,
    cilSun,
    cilSearch,
    cilThumbDown,
    cilThumbUp,
    cilTrash,
    cilUser,
    cilTags,
    cilAddressBook,
    cilListRich,
    cilCommentBubble,
    cilHospital,
    cilHome,
    cilHamburgerMenu,
    cilStar,
    cilStarHalf,
    cilCart,
    cilCreditCard,
    cilInfo,
    cilCloudUpload,
    cilCloudDownload,
    cilExpandDown,
    cilArrowLeft,
    cilArrowRight,
    cilArrowUp,
    cilArrowDown,
    cilOptions,
    cilMonitor,
    cilSync,
    cilWarning
  }
)
