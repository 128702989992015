import _has from 'lodash/has'
// import _forEach from 'lodash/forEach'

export const checkoutLinks = {
  namespaced: true,
  state: () => ({
    error: null,
    checkoutLinks: []
  }),
  getters: {
    checkoutLinks (state) {
      return state.checkoutLinks
    }
  },
  mutations: {
    checkoutLinksLoaded: function (state, checkoutLinks) {
      state.error = null
      state.checkoutLinks = checkoutLinks
    }
  },
  actions: {
    loadCheckoutLinks ({ commit, dispatch }) {
      return this.$app.axios.get('/checkoutlink/get', {
      }).then(function (response) {
        // Erfolgreiche Authentifizierung
        commit('checkoutLinksLoaded', response.data)
      })
        .catch((error) => {
          // Es soll trotz eines 401 den Error aus dem Response Body angezeigt werden
          if (error.response.status === 401) {
            const data = error.response.data
            if (_has(data, 'message')) {
              commit('authenticatingError', data.message)
            } else {
              // Sollte nicht eintreten, malformed json response
              commit('authenticatingError', error)
            }
          } else {
            // Anderer HTTP-Status-Code (zum Beispiel 500)
            commit('authenticatingError', error)
          }
        })
    }
  }
}
