import _has from 'lodash/has'

export const config = {
  namespaced: true,
  state: () => ({
    error: null,
    config: localStorage.getItem('config') || []
  }),
  getters: {
    getConfig: (state) => {
      let config = localStorage.getItem('config')
      if (typeof config === 'string') {
        config = JSON.parse(config)
      }
      return config
    },
    getByIdentifier: (state, getters) => (identifier) => {
      if (Array.isArray(state.config) && identifier) {
        return getters.getConfig.filter(config => config.identifier === identifier)
      } else {
        return null
      }
    },
    getValueByIdentifier: (state, getters) => (identifier) => {
      if (Array.isArray(getters.getConfig) && identifier) {
        const configResult = getters.getConfig.filter(config => config.identifier === identifier)
        if (configResult[0]) {
          return configResult[0].value
        } else {
          return identifier
        }
      } else {
        return '!Config Variable nicht gesetzt!'
      }
    }
  },
  actions: {
    load: function ({ state, commit, dispatch }, force = false) {
      if (state.config.length === 0 || force) {
        return this.$app.axios.get('/config/get').then((response) => {
          state.config = response.data
          localStorage.setItem('config', JSON.stringify(state.config))
        }).catch((error) => {
          // Es soll trotz eines 401 den Error aus dem Response Body angezeigt werden
          if (error.response.status === 401) {
            const data = error.response.data
            if (_has(data, 'message')) {
            } else {
              // Sollte nicht eintreten, malformed json response
            }
          } else {
            // Anderer HTTP-Status-Code (zum Beispiel 500)
          }
        })
      }
      console.log('dont dispatch load')
    }
  }
}
