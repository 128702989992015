//   ___ __  __ ____   ___  ____ _____
//  |_ _|  \/  |  _ \ / _ \|  _ \_   _|
//   | || |\/| | |_) | | | | |_) || |
//   | || |  | |  __/| |_| |  _ < | |
//  |___|_|  |_|_|    \___/|_| \_\|_|
//
import { createApp } from 'vue'

import App from './App.vue'
import router from './router'
import store from './store'

import CoreuiVue from '@coreui/vue-pro'
import { CIcon } from '@coreui/icons-vue'
import { iconsSet as icons } from '@/assets/icons'

import axios from 'axios'
import VueAxios from 'vue-axios'
import axiosInterceptors from './router/axios'

import Toast, { POSITION } from 'vue-toastification'

//    ____ ___  _   _ _____ ___ ____
//   / ___/ _ \| \ | |  ___|_ _/ ___|
//  | |  | | | |  \| | |_   | | |  _
//  | |___ |_| | |\  |  _|  | | |_| |
//   \____\___/|_| \_|_|   |___\____|
//
axios.defaults.baseURL = process.env.VUE_APP_API_BASEURL
axiosInterceptors()

const toastOptions = {
  position: POSITION.TOP_RIGHT
}

const app = createApp(App)
app.use(store)
app.use(router)

app.use(VueAxios, axios)

app.use(CoreuiVue)
app.provide('icons', icons)
app.component('CIcon', CIcon)
app.use(Toast, toastOptions)

function setImgSrc (el, value, oldValue) {
  if (oldValue === undefined || value !== oldValue) {
    var imageUrl = value
    axios({
      method: 'get',
      url: imageUrl,
      responseType: 'arraybuffer'
    }).then(function (resp) {
      var mimeType = resp.headers['content-type'].toLowerCase()
      var imgBase64 = Buffer.from(resp.data, 'binary').toString('base64')
      el.src = 'data:' + mimeType + ';base64,' + imgBase64
    }).catch(function () {
      el.src = value
    })
  }
}

app.directive('auth-image', {
  mounted (el, { value, oldValue }) {
    setImgSrc(el, value, oldValue)
  },
  updated (el, { value, oldValue }) {
    setImgSrc(el, value, oldValue)
  }
})

//   ____  _______     __
//  |  _ \| ____\ \   / /
//  | | | |  _|  \ \ / /
//  | |_| | |___  \ V /
//  |____/|_____|  \_/
//
if (process.env.NODE_ENV === 'development') {
  const VueJsonPretty = () => import('vue-json-pretty')
  app.component('vue-json-pretty', VueJsonPretty)
}

//  __     ___   _ _____
//  \ \   / / | | | ____|
//   \ \ / /| | | |  _|
//    \ V / | |_| | |___
//     \_/   \___/|_____|
//
store.$app = app
app.mount('#app')
