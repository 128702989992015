import _has from 'lodash/has'
// import _forEach from 'lodash/forEach'

export const tags = {
  namespaced: true,
  state: () => ({
    error: null,
    items: []
  }),
  getters: {
    tags (state) {
      return state.items
    }
  },
  mutations: {
    tagsLoaded: function (state, tags) {
      state.error = null
      state.items = tags
    }
  },
  actions: {
    loadTags ({ commit, dispatch }) {
      return this.$app.axios.get('/tag/get', {
      }).then(function (response) {
        // Erfolgreiche Authentifizierung
        commit('tagsLoaded', response.data)
      })
        .catch((error) => {
          // Es soll trotz eines 401 den Error aus dem Response Body angezeigt werden
          if (error.response.status === 401) {
            const data = error.response.data
            if (_has(data, 'message')) {
              commit('authenticatingError', data.message)
            } else {
              // Sollte nicht eintreten, malformed json response
              commit('authenticatingError', error)
            }
          } else {
            // Anderer HTTP-Status-Code (zum Beispiel 500)
            commit('authenticatingError', error)
          }
        })
    }
  }
}
